
.image-gallery {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
    gap: 10px;  
}

.image-row {
    display: flex;
    justify-content: center;
    max-height: 30%;
    gap: 10px;
}

.imageP, .imageL {
    max-width: calc(20% - 10px); 
    height: auto; 
}

.imageL {
    max-width: calc(40% - 10px); 
}


@media screen and (max-width: 768px) {

    .image-gallery {
        gap: 5px;
    }

    .imageP, .imageL {
        max-width: calc(20% - 5px); 
    }
    .imageL {
        max-width: calc(40% - 5px);
    }

    .image-row {
        gap: 5px;
    }
}
  