    #landing {
        padding: 0 5rem; 
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 15rem;
    }
    

    .top-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-height: 90vh;
      }
      
      .introContent {
        flex: 1;
      }
      
      
      .profileImg {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .profileImg img {
        max-width: 100%;
        height: auto;
      }


    .bg {
        width: 90%; 
        margin-left: 10%;
        /* justify-content: flex-end; */
        /* height: auto; */
        object-fit: cover;
        border-radius: 50px;
        /* box-shadow: 10px 10px 10px rgba(255, 255, 255, 0.1); */
    }
      

    .hello{
        font-size: 2.5rem;
        font-weight: 200;
        color: var(--primary-color);
        font-family: "Prompt", serif;
        
        /* font-size: larger; */
    }

    .name{
        color: rgb(146, 57, 235);
        font-weight: 300;
        font-family: "Prompt", serif;
        font-size: 4rem;
        white-space: nowrap;
    }

    .aboutpg{
        font-size: 1rem;
        font-weight: 200;
        font-family: "Prompt", serif;
    }

    


    /* .line {
        border-top: 2px solid blue;
        justify-content: center;
        width: 45%;
        margin: 0 10px; 
    } */

 
    /* h2 {
        display: flex;
        align-items: center; 
        margin: 20px 0;
    } */

    .bottom-section-land {
        margin-top: 4rem;
        margin-bottom: 1rem;
        width: 100%;
        text-align: center;
        position: relative; /* Position the pseudo-element relative to this container */
    }
    
    .bottom-section-land::before,
    .bottom-section-land::after {
        content: "";
        position: absolute;
        top: 50%;
        width: calc(50% - 50px); /* Set the width dynamically */
        height: 2px;
        background-color: white;
    }
    
    .bottom-section-land::before {
        left: 0;
    }
    
    .bottom-section-land::after {
        right: 0;
    }
    
    h2 {
        display: inline-block;
        position: relative; 
        z-index: 1; 
        padding: 0 10px; 
        /* transform: translateY(50%); */
    }

    .paragraphsPart{
        width: 100%;
        text-align: center;
        margin-bottom: 4rem;
    }
    /* .bottom-section{
        margin-top: 4rem;
        margin-bottom: 4rem; 
        text-align: center; 
    }

    
    .line{
        display: inline-block;
        width: 40%;
        max-width: 50px; 
        height: 2px;
        background-color: blueviolet; 
    }
    
    h2 {
        margin: 20px 0; 
    }

    h2 span {
        flex: none;
    } */

    .aboutTimeline {
      display: flex;
    }
    
    .sidebar {
      position: fixed;
      left: 50px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      transition: opacity 0.3s ease;
      opacity: 0;
      z-index: 1000;
    }

    .sidebar.visible {
      opacity: 1; 
    }
    
    .line {
      width: 4px;
      background-color: #000000; 
      position: absolute; 
      left: 50%; 
      transform: translateX(-50%); 
      height: calc(30px * 5 + 10px * 4);
    }    
    
    .circles {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;
    }
    
    .circle {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      /* background-color: #5c5b5b; color is done in js file  */
      transition: background-color 0.3s ease;
    }

    /* .circle.active {
      background-color: #9239eb; color changed in code
    } */
    
    
    .AboutContent{
      width: 100%;
    }

    div {
      transition: background-color 0.5s ease; /* Smooth transition */
    }

    /* <img src={ME1} alt="Profile" className="abp1"/>
                        <img src={ME1} alt="Profile" className="abp2"/>
                        <img src={ME1} alt="Profile" className="abp3"/>
                        <h2>About Section 1</h2 */

    /* <div className="paragraphsPart"> 
                    <p>I grew up south of Boston and absolutely love New England. To get out of my comfort zone I attended Virginia Tech and studied 
                        Computational Modeling and Data Analytics with a concentration in Biological Sciences. Since graduating in 2021 I have been working for 
                        JDSAT in a wide variety of roles, addressing the needs of this small but rapidly growing company. My responsibilities have included analytical work, 
                        model development, dashboard design, frontend and backend development, and data engineering among others. Including my internships, 
                        I have worked in many different fields from solar energy, cyber security, research, insurance, and now Navy medicine. 
                        This versatility has helped me develop into a well rounded programmer.
                    </p>
                    <p>Out of the office, you'll often find me embracing the great outdoors, whether it's skiing, trekking through scenic trails, or casting a line while fishing. 
                        I'm also an avid sports enthusiast, enjoying pick-up games of basketball or soccer whenever I can. As a devoted hockey fan (Go Bruins!), 
                        I never miss a chance to catch a game. Additionally, I have a soft spot for animals and am currently fostering my second dog. 
                        Traveling is another one of my greatest passions, where I relish exploring new destinations, meeting diverse people, and experiencing different cuisines. 
                        Recent highlights include skiing in Lake Tahoe and Salt Lake City, as well as memorable trips to visit my sister in Indonesia and Thailand. 
                        For more glimpses into my adventures, feel free to explore the travel tab above!

                    </p>
                </div>    */



.house-icon {
  position: absolute; /* Position it absolutely */
  top: -40px; 
  left: 2px;
  /* left: calc(50% - 13px); */
  font-size: 24px; /* Size of the icon */
  color: #9239eb; /* Change to match your color scheme */
  transition: opacity 0.3s ease; /* Smooth transition */
  opacity: 0; /* Initially hidden */
}

.sidebar.visible .house-icon {
  opacity: 1; /* Show icon when sidebar is visible */
}


.aboutPage {
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* align-items: center; */
  /* color: white;  */
  /* background-size: cover; */
  margin-bottom: 5rem;
}





.abTitle {
  text-align: center;
  width: 100%;
  /* height: 5%; */
  font-size: 2.5rem;
  font-family: 'Prompt', sans-serif;
  
}
.c1{
  color: rgb(182, 117, 243);
  
  /* font-weight: bold; */
  /* color: #9239eb */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
}

.c3{
  color: rgb(146, 57, 235);
  /* font-weight: bold; */
  /* color: #9239eb */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 

}

.c4{
  color: rgb( 91, 35, 145);
  /* font-weight: bold; */
  /* color: #9239eb */
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5); 

 
}

.abpara {
  flex: 1;
  width: 50%;
  font-size: 1.7rem;
  color:rgb(91, 35, 145);
  font-family: "Poppins", serif;
  text-align: center;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  */
  /* text-shadow: 
    -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000;  */
  /* background-color: rgba(0, 0, 0, 0.5); Black with 50% opacity */
  padding: 10px; /* Add some padding for better appearance */
  border-radius: 10px;

}

.topBody{
  color: whitesmoke;
}

.bottomBody{
  color: rgb(0, 0, 0)
}

.normalLayout {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
}

.reverseLayout {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}





.abp {
  width: 30%; 
  height: auto;
  border-radius: 10px; /* Add border-radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow effect */
}
.aboutImages {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.aboutImages .row {
  display: flex; /* Use flexbox for each row */
  /* flex: 1; */
}

.aboutImages.ab1 .row:first-child {
  justify-content: space-evenly; /* Space between images in the first row */
  width: 100%;
  height: 30%;
}

.aboutImages.ab1 .row:nth-child(2) {
  justify-content: center; 
  width: 100%;
}
.aboutImages.ab1 .row:first-child img {
  width: 45%;
  /* height: auto;  */
  margin: 10px;
  object-fit: cover;
}
.aboutImages.ab1 .row:nth-child(2) img {
  width: 60%;
  /* height: auto;  */
  margin: 10px;
  object-fit: cover;
}






.aboutImages2 {
  display: flex;
  flex-direction: column;
  flex: 2;
  height: 90vh;
  /* gap: 10px; */
}

.row3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.rowSplit {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 2;
}

.abpLandscape {
  width: 25vw; 
  object-fit: cover;
  border-radius: 10px; /* Add border-radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.abpP {
  width: 40vh; 
  object-fit: cover;
  border-radius: 10px; /* Add border-radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.abpLandscape2 {
  width: 40vw; 
  object-fit: cover;
  border-radius: 10px; /* Add border-radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}




.Bottom {
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 5rem;
}


.aboutSectionContent {
  display: flex;
  flex-grow: 1;
  padding-right: 2rem;
  padding-left: 7%;
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
}
.aboutSectionBottom {
  display: flex;
  flex-grow: 1;
  padding-right: 2rem;
  padding-left: 7%;
  flex-direction: column;
}

.part1 {
  display: flex;
  flex-direction: row; /* Change to row to align text and images side by side */
  width: 100%; /* Each part takes the full width */
  padding-bottom: 2rem;
  align-items: center;
}



.abpara2 {
  flex: 1; /* This will take 1/3 of the width */
  /* font-family: 'Assistant', sans-serif; */
  font-family: "Poppins", serif;
  font-size: 1.7rem;
  /* color:rgb(146, 57, 235); */
  /* width: 50%; */
  text-align: center;
  align-items: center;
}

.pics {
  flex: 2; /* This will take 2/3 of the width */
  display: flex;
  justify-content: space-around; /* Optional for spacing the images evenly */
  align-items: center; /* Center images vertically */
  opacity: 1;
}
.abpdouble {
  width: 45%; 
  height: auto;
  border-radius: 10px; /* Add border-radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow effect */
}
.abpport {
  width: 40%; 
  height: auto;
  border-radius: 10px; /* Add border-radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow effect */
}
.abpsingle{
  width: 60%;
  height: auto;
  border-radius: 10px; /* Add border-radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.mobileImagesLayout{
  display: none;
}


@media screen and (max-width: 768px) {
  .top-section{
      flex-direction: column;
      /* justify-content: space-evenly; */
  }
  .bg {
      max-width: 100%; 
      margin-left: 0;
      border-radius: 20px;
  }
  

  .introContent,
  .profileImg {
      padding-top: 6rem;
      flex: none;
      width: 100%;
  }
  .top-section {
      justify-content:flex-start
    }

  /* .profileImg {
      display: flex;
      justify-content: center;
      align-items: center;
    } */

  .hello{
      font-size: 2rem;
      font-weight: 200;
  }

  .name{
      font-weight: 200;
  }
  #landing {
      padding: 0 2rem; 
  }
  .aboutpg{
      font-size: small;
  }


  
  .sidebar {
    left: 20px;
  }
  .aboutImages {
    display: none;
  }
  .abpara.topBody, .abpara.bottomBody, .mobileBottomText{
    flex: none;
    font-size: 1rem;
    /* text-align: right; */
    width: 100%;
   
    margin-left: 30px;
    font-family: "Poppins", serif;
  }

  .mobileBottomText{
    padding: 0 30px;
  }
  .abTitle.abTitle{
    font-size: 1.5rem;
    width: 100%;
    /* text-align: right; */
  }

  .aboutSectionContent {
    display: flex;
    flex-grow: 1; 
    flex-direction: column;
    align-items: center;
    
  }
  
  .abpara2.bottomBody, .part1{
    display: none;
  }

  
  /* .row {
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    margin: 10px 0; 
  }
  .pics {
    flex-direction: column; 
    align-items: center; 
  }
  .rowSplit{
    flex-direction: column;
  }
  .aboutImages.ab1 .row:first-child img {
    width: 65%;
  }
  .aboutImages.ab1 .row:nth-child(2) img {
    width: 65%;
  } */


  .row, .pics, .rowSplit, .row3 {
    display: none;
  }

  .abpLandscape {
    width: 65vw; 
  }
  
  .abpP {
    width: 60vw;
  }
  
  
  .abpLandscape2 {
    width: 65vw; 
  }
  .mobileImagesLayout{
    flex-direction: column; 
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    display: flex;
    flex-grow: 1;
  }
  .aboutImages .ab1{
    display: none;
  }
  .aboutImages2 .ab2{
    display: none;
  }
  .aboutImages2 {
    display: none;
  }
  .abp{
    width: 75%;
  }
  .mobileBottomText{
    color:rgb(0, 0, 0);
  }

  .mblpt{
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .abpdouble{
    width: 80%;
  }
  .abpport{
    width: 60%;
  }
  .abpsingle{
    width: 80%;
  }




}