.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: x-large;
    color: white
}

.text-center {
    width: 100%; 
    max-width: 300px;
}

.main a {
    color: rgb(146, 57, 235);
    text-decoration: none;
  }

@media screen and (max-width: 768px) {
    .main{
        font-size: medium;
    }
}