.videoPage{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    flex-direction: column;
    padding: 0 5rem; 
}

.videoPlayerPE{
    width:55%;
    height: auto;
}

.bottom-section-pe {
    margin-top: 4rem;
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
    position: relative; /* Position the pseudo-element relative to this container */
}

.bottom-section-pe::before,
.bottom-section-pe::after {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(50% - 55px); /* Set the width dynamically */
    height: 2px;
    background-color: white;
}

.bottom-section-pe::before {
    left: 0;
}

.bottom-section-pe::after {
    right: 0;
}

h2 {
    display: inline-block;
    position: relative; 
    z-index: 1; 
    padding: 0 10px; 
    /* transform: translateY(50%); */
}

.paragraphsPart{
    width: 100%;
    text-align: center;
    margin-bottom: 4rem;
}



@media screen and (max-width: 768px) {
    .about-section {
        /* flex: 1; */
        width: 75%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;
    }
    .videoPage{
        padding: 0 2rem; 
    }
    .videoPlayerPE{
        width:100%;
        height: auto;
        padding-top: 1rem;
    }
}