
  .image-page-photo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    gap: 10px;
  }
.image-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}
.image-link:hover {
    filter: brightness(80%); 
}
.image {
  max-width: 30%;
  max-height: 100%;
  object-fit: cover;
  height: auto;
  flex: 0 0 auto;
}
.image-wrapper {
  position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    max-width: 100%;
    /* overflow-x: auto; */
}

.overlay-text {
  position: absolute; 
  top: 10px; 
  left: 50%; 
  transform: translateX(-50%);
  background-color: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  color: white;
  padding: 10px;
  text-align: center;
  width: 100%; 
  font-size: 2rem; 
  font-weight: 550;
  z-index: 2; 
}

@media screen and (max-width: 768px) {
  .overlay-text{
    font-size: 1.25rem; 
  }
        
}

@media screen and (max-width: 500px) {
  .overlay-text{
    top: 5px; 
    padding: 0px;
    font-size: 0.75rem; 
    
  }

  .image-wrapper{
    gap: 5px;
  }

  .image-page-photo{
    padding-top: 1rem;
    padding: 0px;
    gap: 5px;
  }
        
}

.imageResizer {
  width: 100%; /* Define a specific width for the image container */
  /* height: 150px;  */
  overflow: hidden; /* Ensure images are clipped if they exceed container dimensions */
}

.imageResizer img {
  width: 100%; /* Ensure images fill the container horizontally */
  height: auto; /* Allow images to scale proportionally */
}