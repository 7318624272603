.navbar {
    background: rgb(30, 30, 30);
    height: 5rem;
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: sticky; */
    top: 0;
    z-index: 3;
}

.navbar-content-mobile{
    display: none;    

}

.logoMobile {
    display: none;
}

.logo {
    display: flex;
    object-fit: fill;
    height: 3.5rem;
    width: 3.5rem;
}

.mobileMenu {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items vertically */
}

.mobileMenuItems {
    display: none;
    flex-direction: column;
    align-items: center; /* Center items vertically */
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgb(41, 41, 41);
    z-index: 2;
    transition: top 0.3s;
}

.mobileMenuItems.active {
    top: 5rem; 
    display: flex;
}


.mobileMenuItems.active + .content {
    padding-top: calc(5rem + 100px); /* Adjust this value based on the height of your navbar and any additional padding/margin */
}

.mobileMenuItem {
    padding: 0.5rem; /* Add padding for better touch area */
    color: white;
    text-decoration: none;
    transition: background-color 0.3s; /* Add transition for hover effect */
}

.mobileMenuItem:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Change background color on hover */
}

.desktopWrapper {
    background: rgb(184, 183, 183, 0.95);
    height: 5rem;
    width: 60%;
    margin: 1% auto;
    /* margin-left: 20%; */
    /* margin-top: 1%; */
    position: fixed;
    top: 0;
    z-index: 1;
    border-radius: 2rem;
    border: 3px solid #f1f1f1;
    filter: blur(1px);
}

.navbar-content-desktop {
    width: 60%;
    margin-top: 2%;
    display: flex;
    padding: 1rem;
    align-items: center;
    /* justify-content: space-between; */
    z-index: 3;
    position: fixed;
    
}

.desktopMenu {
    max-width: 80rem; 
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.desktopMenuListItemContact {
    display: inline-block; 
    padding: 10px 20px; 
    border-radius: 2rem; 
    background-color: #f0f0f0; 
    color: rgb(146, 57, 235);
    text-decoration: none; 
    font-weight: bold; 
}

.desktopMenuListItem{
    /* margin: 1rem; */
    cursor: pointer;
    color: rgb(146, 57, 235);
    margin-right: 20px;
    font-weight: bold;
}

.desktopMenuListItem:hover{
    color: rgb(146, 57, 235);
    /* padding: 0.5rem; */
    border-bottom: 3px solid rgb(146, 57, 235);
}
.desktopMenuBtn{
    background: white;
    padding: 0.5rem 0.5rem;
    border-radius: 2rem;
    /* margin: 1rem; */
    cursor: pointer;
}
.mobileMenuBtn {
    background: transparent;
    border: none;
    cursor: pointer;
    color: rgb(146, 57, 235);
    font-size: 1rem;
    display: none; /* Hide button for larger screens */
}

.extended-content {
    padding-top: calc(12rem); 
}


/* Show mobile menu button for smaller screens */
@media screen and (max-width: 768px) {
    

    .mobileMenuBtn {
        display: block; /* Show menu button for smaller screens */
    }
    /* .content {
    padding-top: 5rem; 
    } */
    
    .logoMobile {
        display: flex;
        object-fit: cover;
        height: 4rem;
        width: 4rem;
    }
   
    

    .navbar-content-mobile {
        width: 100%; /* Allow content to take full width of navbar */
        margin: 0 auto; /* Center content horizontally */
        display: flex;
        align-items: center;
        justify-content:space-between;
        margin-top: 2%;
    }

    .desktopMenu {
        display: none;
    }

    .logo, .desktopMenuBtn{
        display: none;
    }
    
    .desktopWrapper{
        display: none;
    }
    .navbar-content-desktop{
        display: none;
    }
}