.projectPage{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    min-height: 100vh;
    flex-direction: column;
    padding: 0 5rem; 
}

.videoPlayer{
    width:55%;
    height: auto;
    /* max-width: 100%;
    max-height: 100%; */
}




.bottom-section-robo {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
    position: relative; /* Position the pseudo-element relative to this container */
}

.bottom-section-robo::before,
.bottom-section-robo::after {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(50% - 100px); /* Set the width dynamically */
    height: 2px;
    background-color: white;
}

.bottom-section-robo::before {
    left: 0;
}

.bottom-section-robo::after {
    right: 0;
}

h2 {
    display: inline-block;
    position: relative; 
    z-index: 1; 
    padding: 0 10px; 
    /* transform: translateY(50%); */
}

.paragraphsPart2{
    width: 100%;
    text-align: center;
}

.roboflowPart{
    margin-bottom: 4rem;
    margin-top: 2rem;
}
.main-list ul {
    padding-left: 20px; 
}
.plots{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.plotimage {
    width: 45%;
}

@media screen and (max-width: 768px) {
    .plotimage{
        width: 100%;
    }
    .projectPage{
        padding: 0 2rem; 
    }
    .bottom-section-robo::before,
    .bottom-section-robo::after {
    width: calc(50% - 80px); /* Set the width dynamically */
}
}