.center {
    text-align: center; 
    padding: 0 10px; 
}


@media screen and (max-width: 768px) {
    .footer {
        padding: 10px;
    }

    .center:nth-child(1) {
        display: none; 
    }
}


@media screen and (max-width: 500px) {
    .footer{
        font-size: 0.75rem; 
    }
    
          
  }