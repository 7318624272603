/* .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loader-slide-up {
    animation: slideUp 1s forwards;
  }
  
  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  } */
  

 .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(30, 30, 30);
    z-index: 1000; 
  }
  
  .logoload {
    width: 50px;
    animation: growLogo 2s forwards;

    -webkit-animation: growLogo 2s forwards; /* Safari and Chrome */
    -moz-animation: growLogo 2s forwards; /* Firefox */
    -o-animation: growLogo 2s forwards; 
  }
  
  @keyframes growLogo {
    0% {
        width: 50px;
        opacity: 0;
    }
    100% {
        width: 400px; 
        opacity: 1;
    }
}

@-webkit-keyframes growLogo {
    0% {
        width: 50px;
        opacity: 0;
    }
    100% {
        width: 400px; 
        opacity: 1;
    }
}

@-moz-keyframes growLogo {
    0% {
        width: 50px;
        opacity: 0;
    }
    100% {
        width: 400px; 
        opacity: 1;
    }
}

@-o-keyframes growLogo {
    0% {
        width: 50px;
        opacity: 0;
    }
    100% {
        width: 400px; 
        opacity: 1;
    }
}
  
  @keyframes slideUp {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
  
  .hidden-content-load {
    display: none; 
    opacity: 0;
  }
  
  .visible-content-load {
    display: block;
    opacity: 1;
    animation: fadeIn 1s forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
   